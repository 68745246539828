
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { getMainStayList, getMainAiStayList } from '@/api/stay';
import { addFavoriteItem } from '@/api/favoriteItem';
import { UserModule } from '@/store/user';
import { getMainStoreInfoList, getAiStoreInfoList } from '@/api/storeInfo';
import { getIndustryList } from '@/api/industry';
import Calendar from '@/components/Calendar/index.vue';
import { getOptionItemList } from '@/api/optionItem';

@Component({
  components: {
    Calendar,
  },
})

export default class extends Vue {
  mounted() {
    this.getIndustryList();
    this.init();
    this.getRegionList();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private searchedIndustryIdx: any = null;

  private totalSearchQuery: any = {
    searchValue: '',
    industryIdx: null,
    useDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: 1,
    child: 0,
    page: 0,
    size: 4,
  }

  private stayTotalSearchQuery: any = {
    searchValue: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: 1,
    child: 0,
    page: 0,
    size: 4,
  }

  private listQuery: any = {
    page: 0,
    size: 4,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    lat: '37.566826',
    lng: '126.9786567',
    sort: 'distance',
    region: '',
    adult: 2,
    child: 0,
    startPrice: 0,
    endPrice: 1000000,
    categories: '',
    options: '',
  }

  private dateRange = [
    moment().format('YYYY-MM-DD'),
    moment().add(1, 'days').format('YYYY-MM-DD'),
  ];

  private centerDialogVisible= false;

  private slotVisible= false;

  private stayList = [];

  private stayAiList = [];

  private mainItem = {
    restaurantList: [],
    placeList: [],
    leisureList: [],
    excitingList: [],
    productList: [],
  }

  /* 추천검색 */
  private aiItem = {
    stayList: [],
    restaurantList: [],
    placeList: [],
    leisureList: [],
    excitingList: [],
    productList: [],
  }

  private formQuery: any = {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: 2,
    child: 0,
  }

  private regionList = [];

  private industryList = [];

  private selectedPersonnel() {
    this.slotVisible = !this.slotVisible;
  }

  /* 타이틀 */
  private getIndustryList() {
    getIndustryList().then((res) => {
      this.industryList = res.data;
    });
  }

  private getRegionList() {
    getOptionItemList('REGION').then((res) => {
      this.regionList = res.data;
    });
  }

  private getMainStayList() {
    getMainAiStayList({ ...this.listQuery }).then((res2) => {
      this.stayAiList = res2.data;
    });
  }

  private handleAddFavoriteStay(idx: any) {
    if (UserModule.token) {
      addFavoriteItem({ stayIdx: idx }).then(() => {
        this.$message.success('저장되었습니다.');
      });
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
  }

  private getAiItemList() {
    getAiStoreInfoList({
      page: 0,
      size: 4,
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    }).then((res) => {
      this.aiItem = {
        ...this.aiItem,
        ...res.data,
      };
    });
  }

  private getPickerOptions() {
    const options = {
      disabledDate(time: any) {
        return moment(time).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
      },
    };
    return options;
  }

  private handleTotalSearch() {
    this.$router.push({ name: 'TotalSearch', query: { ...this.totalSearchQuery } }).then(() => {
      this.init();
    }).catch(() => {
      this.centerDialogVisible = false;
      this.init();
    });
  }

  private async init() {
    this.totalSearchQuery = {
      ...this.totalSearchQuery,
      ...this.$route.query,
    };
    this.stayTotalSearchQuery = {
      ...this.stayTotalSearchQuery,
      ...this.$route.query,
      startDate: this.$route.query.useDate,
    };
    // await this.getMainStoreInfoList();
    await this.getMainStayList();
    await this.getAiItemList();
    this.searchedIndustryIdx = this.$route.query.industryIdx;
  }

  private truncateName(name: string, maxLength: number) :string {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`;
    }
    return name;
  }

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.totalSearchQuery.startDate = startDate;
    this.totalSearchQuery.endDate = endDate;
    this.stayTotalSearchQuery.startDate = startDate;
    this.stayTotalSearchQuery.endDate = endDate;
  }
}
